<!--
 * @Author: your name
 * @Date: 2021-12-15 11:38:15
 * @LastEditTime: 2022-01-24 18:57:49
 * @LastEditors: Please set LastEditors
 * @Description:无人车
 * @FilePath: \new-website-1123\src\views\special\car\index.vue
-->
<template>
  <div class="xj101-page">
    <img
      src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/common/01.jpg"
      style="display: block; width: 100%"
    />
    <img
      src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/common/02.jpg"
      style="display: block; width: 100%"
    />
    <img
      src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/common/03.jpg"
      style="display: block; width: 100%"
    />
  </div>
</template>

<script>
import {getNesViewApi} from '@/api/index.js'
export default {
  name: "",
  components: {},
  data() {
    return {
      scrollTop: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.cScroll, false);
    this.addView()
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.cScroll, false);
  },
  methods: {
    addView(){
      getNesViewApi({type:1,pid:"182ae191b8484fe89b3c5787787e2fa3"})
    },
    /**
     * @description: 监听滚动事件
     */
    cScroll() {
      let html = document.querySelector("html");
      this.scrollTop = html.scrollTop;
      if (window.innerWidth > 800) {
        this.page2s();
      }
    },
    page2s() {
      let box2 = document.querySelector(".sticky-box1");
      let div = box2.querySelector(".xj101-page2");
      let img = box2.querySelector(".img-div");
      let text1 = div.querySelector(".text1-div");
      let text2 = div.querySelector(".text2-div");
      let topLabel = text1.querySelector(".t-top");
      let topDesc = text1.querySelector(".desc-p");
      let msg = text1.querySelector(".msg-ul");
      let filterBox = div.querySelector(".filter-div");

      let ctop = this.scrollTop - box2.offsetTop;
      if (ctop > 2100) {
        let rate = Math.min(1, (ctop - 2100) / 300);
        text2.style.opacity = rate;
        text1.style.opacity = 0;
        filterBox.style.opacity = 1;
      } else if (ctop > 1800) {
        let rate = Math.min(1, (ctop - 1800) / 300);
        text2.style.opacity = 0;
        text1.style.opacity = 1 - rate;
        filterBox.style.opacity = rate;
      } else if (ctop > 1600) {
        text1.style.opacity = 1;
        topLabel.style.transform = `translateY(0%)`;
        msg.style.opacity = topDesc.style.opacity = 1;
        msg.style.transform = `translateY(0%)`;
        text1.style.opacity = 1;
      } else if (ctop > 1200) {
        let rate = Math.min(1, (ctop - 1200) / 400);
        topLabel.style.transform = `translateY(${(1 - rate) * 100}%)`;
        msg.style.opacity = topDesc.style.opacity = rate;
        msg.style.transform = `translateY(-${(1 - rate) * 20}%)`;
        text1.style.opacity = 1;
      } else if (ctop > 700) {
        msg.style.opacity = topDesc.style.opacity = 0;
        topLabel.style.transform = `translateY(100%)`;
        let rate = (ctop - 700) / 500;
        let scale = Math.max(1, (1 - rate) * 4);
        text1.style.transform = `scale(${scale})`;
        topLabel.style.opacity = rate;
      } else if (ctop > 200) {
        topLabel.style.opacity = 0;
        let rate = (ctop - 200) / 500;
        img.style.width = 40 + rate * 10 + "%";
        img.style.transform = `translateY(${rate * 50}px)`;
      } else if (ctop < 200) {
        topLabel.style.opacity = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky-child {
  position: sticky;
  top: 0;
}
.sticky-box1 {
  height: calc(100vh + 2700px);
}
@media screen and (max-width: $mobile-width) {
  .sticky-child {
    position: relative;
    top: 0;
  }
  .xj101-page {
    padding-top: 44px;
  }
  .sticky-box1 {
    height: auto;
  }
}
</style>
